module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"grounding-card\"> ")
    ;  if (state.entity.content != null && state.entity.content.grounding_exercise != null && state.entity.content.grounding_exercise.grounding_initial != null && state.entity.content.grounding_exercise.grounding_initial.title != null) { 
    ; __append(" <h2>")
    ; __append( state.entity.content.grounding_exercise.grounding_initial.title )
    ; __append("</h2> ")
    ;  } else { 
    ; __append(" <h2>")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_INITIAL_TITLE )
    ; __append("</h2> ")
    ;  } 
    ; __append(" ")
    ;  if (state.entity.content != null && state.entity.content.grounding_exercise != null && state.entity.content.grounding_exercise.grounding_initial != null && state.entity.content.grounding_exercise.grounding_initial.content != null) { 
    ; __append(" <p>")
    ; __append( state.entity.content.grounding_exercise.grounding_initial.content )
    ; __append("</p> ")
    ;  } else { 
    ; __append(" <p>")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_INITIAL_CONTENT )
    ; __append("</p> ")
    ;  } 
    ; __append(" <button class=\"dark-blue-cta solid animate\" id=\"start-grounding-button\" title=\"")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_INITIAL_START_BUTTON )
    ; __append("\" aria-label=\"")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_INITIAL_START_BUTTON )
    ; __append("\"> ")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_INITIAL_START_BUTTON )
    ; __append(" </button> <button class=\"secondary-button animate\" id=\"skip-grounding-button\" title=\"")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_INITIAL_SKIP_BUTTON )
    ; __append("\" aria-label=\"")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_INITIAL_SKIP_BUTTON )
    ; __append("\"> ")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_INITIAL_SKIP_BUTTON )
    ; __append(" </button></div>")
  }
  return __output;

}