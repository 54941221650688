module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"grid-item column large-4-col medium-6-col small-12-col\"><div class=\"story-card no-padding animate\" data-link=\"")
    ; __append(escapeFn( linkComponent ))
    ; __append("\" data-card=\"story-card\"><div class=\"reaction-badge-container\" data-id=\"")
    ; __append(escapeFn( id ))
    ; __append("\"> ")
    ;  reactions.forEach((reaction) => { 
    ; __append(" ")
    ; (function(){
    ; __append("<div class=\"reaction-badge ")
    ; __append(escapeFn( reaction.position ))
    ; __append(" ")
    ; __append(escapeFn( reaction.icon ))
    ; __append(" ")
    ;  if (reaction.shouldAnimate === true) { 
    ; __append(" reaction-add-bounce ")
    ;  } 
    ; __append("\">\n    <div class=\"legend-reaction\"><span>")
    ; __append(escapeFn( reaction.legend ))
    ; __append("<i class=\"far fa-times-circle\" name=\"reaction-remove-selector\"></i></span></div>\n</div>\n")
    ; }).call(this)
    ; __append(" ")
    ;  }) 
    ; __append(" </div><div class=\"story-card-inner\"><div class=\"badge-container\"> ")
    ;  if (partner_entity != null) { 
    ; __append(" <div class=\"partner-community-message animate\"><p><a title=\"")
    ; __append(escapeFn( partner_entity.name ))
    ; __append(" ")
    ; __append( locale.content.GENERAL_COMMUNITY )
    ; __append("\" aria-label=\"")
    ; __append(escapeFn( partner_entity.name ))
    ; __append(" ")
    ; __append( locale.content.GENERAL_COMMUNITY )
    ; __append("\" href=\"")
    ; __append( `${partner_entity.analytics_url}&url_path=${locale.url.path}` )
    ; __append("\" target=\"_blank\" name=\"partner-community\"> ")
    ; __append(escapeFn( partner_entity.name ))
    ; __append(" ")
    ; __append( locale.content.GENERAL_COMMUNITY )
    ; __append(" </a></p></div> ")
    ;  } 
    ; __append("  ")
    ;  if (update_unread_count > 0) { 
    ; __append(" <div class=\"update-badge-message animate\"><i class=\"far fa-clock\"></i><p>")
    ; __append(escapeFn( update_unread_count ))
    ; __append(" ")
    ; __append(escapeFn( update_unread_count === 1 ? locale.content.PARTIAL_STORY_CARD_NEW_UPDATE : locale.content.PARTIAL_STORY_CARD_NEW_UPDATES ))
    ; __append("</p></div> ")
    ;  } 
    ; __append(" </div><h2><span class=\"animate\">")
    ; __append(escapeFn( title ))
    ; __append("</span></h2> ")
    ;  if (media != null) { 
    ; __append(" <div class=\"preview-container")
    ;  if (is_triggering) { 
    ; __append(" warning ")
    ;  } 
    ; __append("\"> ")
    ;  if (is_triggering) { 
    ; __append(" <div class=\"trigger-toggle\"><div class=\"lighten-overlay\"></div><span class=\"animate\" name=\"trigger-toggle\">")
    ; __append( locale.content.PARTIAL_STORY_CARD_SHOW_ARTWORK )
    ; __append("</span></div> ")
    ;  } 
    ; __append(" <div class=\"overlay\"></div><img class=\"survivor-media\" src=\"")
    ; __append(escapeFn( media ))
    ; __append("\" alt=\"Survivor Media\" title=\"Survivor Media\"/></div> ")
    ;  } else { 
    ; __append(" <p><span class=\"animate\">")
    ; __append(escapeFn( content ))
    ; __append("</span></p> ")
    ;  } 
    ; __append("  ")
    ;  if (trigger_message != null && trigger_message != "") { 
    ; __append(" <div class=\"warning-message\"><p>")
    ; __append( trigger_message )
    ; __append("</p></div> ")
    ;  } 
    ; __append(" <div class=\"support-container animate\"><div class=\"reactions-trigger animate\"><button class=\"animate\" name=\"support-button\" title=\"")
    ; __append( locale.content.PARTIAL_STORY_CARD_SUPPORT )
    ; __append("\" aria-label=\"")
    ; __append( locale.content.PARTIAL_STORY_CARD_SUPPORT )
    ; __append("\"><div class=\"show-support-icon\"></div><span class=\"show-support-span\">")
    ; __append( locale.content.PARTIAL_STORY_CARD_SUPPORT )
    ; __append("</span></button></div><div class=\"reactions-box\" data-id=\"")
    ; __append(escapeFn( id ))
    ; __append("\"><input type=\"checkbox\" id=\"reactions-manual-trigger\" class=\"field-reactions\"><div class=\"reactions-toolbox\"></div><button class=\"reaction-icon reaction-a-pos reaction-a-icon\" name=\"reaction-selector\" title=\"")
    ; __append( locale.content.REACTIONS.reaction_a )
    ; __append("\" aria-label=\"")
    ; __append( locale.content.REACTIONS.reaction_a )
    ; __append("\"><div class=\"legend-reaction\"><span>")
    ; __append( locale.content.REACTIONS.reaction_a )
    ; __append("</span></div></button> <button class=\"reaction-icon reaction-b-pos reaction-b-icon\" name=\"reaction-selector\" title=\"")
    ; __append( locale.content.REACTIONS.reaction_b )
    ; __append("\" aria-label=\"")
    ; __append( locale.content.REACTIONS.reaction_b )
    ; __append("\"><div class=\"legend-reaction\"><span>")
    ; __append( locale.content.REACTIONS.reaction_b )
    ; __append("</span></div></button> <button class=\"reaction-icon reaction-d-pos reaction-d-icon\" name=\"reaction-selector\" title=\"")
    ; __append( locale.content.REACTIONS.reaction_d )
    ; __append("\" aria-label=\"")
    ; __append( locale.content.REACTIONS.reaction_d )
    ; __append("\"><div class=\"legend-reaction\"><span>")
    ; __append( locale.content.REACTIONS.reaction_d )
    ; __append("</span></div></button> <button class=\"reaction-icon reaction-e-pos reaction-e-icon\" name=\"reaction-selector\" title=\"")
    ; __append( locale.content.REACTIONS.reaction_e )
    ; __append("\" aria-label=\"")
    ; __append( locale.content.REACTIONS.reaction_e )
    ; __append("\"><div class=\"legend-reaction\"><span>")
    ; __append( locale.content.REACTIONS.reaction_e )
    ; __append("</span></div></button></div></div><div class=\"reactions-placeholder\"></div><div class=\"story-actions-container\"><div class=\"grid-x align-right\"><div class=\"cell small-6 center-align\"><a title=\"")
    ; __append( locale.content.PARTIAL_STORY_CARD_READ_STORY )
    ; __append("\" aria-label=\"")
    ; __append( locale.content.PARTIAL_STORY_CARD_READ_STORY )
    ; __append("\" class=\"story-action-button animate\" href=\"")
    ; __append( locale.locale_path )
    ; __append("/story/")
    ; __append(escapeFn( linkComponent ))
    ; __append("\" name=\"read-story\"><div class=\"read-story-icon\"></div><span>")
    ; __append( locale.content.PARTIAL_STORY_CARD_READ_STORY )
    ; __append("</span></a></div></div></div></div></div></div>")
  }
  return __output;

}